// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

// Font Variables
// $poppins: 'Poppins', serif; 

// START : Request - Kanban
html{ background: #F8F8FB !important }
html, body {
    height: 100%;
  }
tbody a{color: #495057!important}
p{ margin-top: 0!important; margin-bottom: 0!important; }
tbody span a{color: #828282!important}
// flex global css
.flex-item:nth-child(2){  margin-left: 12px };
.flex-1{ flex: 1 };
.flex-right{ justify-content: flex-end };
.flex-right.resend{ text-align-last: right };
.flex-right.resend button{font-weight: 400!important;}
.item-center{ align-items: center };
.item-baseline{ align-items: baseline };
.flex-row{
    display: flex;
    flex-flow: wrap row;
    align-items: center;
}
// end flex global css

div[data-rbd-droppable-id="board-droppable"] {

    // Board's div style
    > div{
        border-radius: 5px;
        padding: 15px 0px;
        background-color: white;
    }

    .card{
        margin: 10px 15px;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.06);
    }

    .card-title{
        margin: 10px 0px!important;
        padding: 0px 18px;
    }
}
.status{
    border-radius: 20px;
    padding: 5px 20px 5px 20px;
    background: rgba(166, 176, 207, 0.12);
    color: #828282;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}

.right-badges{
    padding-left: 10px;
    padding-bottom: 10px;
}

.status-completed {
    background: rgba(52, 195, 143, 0.12);
    color: #34C38F;
}

.btn{
    color: #AFB6CB !important;
    font-weight: 500 !important;
    font-size: 13px;
    line-height: 19px !important;
}

.btn i{ margin-right: 5px }


.btn-default{
    background: #FFFFFF!important;
    font-size: 13px!important;
    line-height: 19px!important;
    color: #8890AB!important;
    border: 1px solid #ECECF5 !important;
    border-radius: 4px!important;
}

.btn-clear{
    background:  #fff !important;
    font-size: 13px!important;
    line-height: 19px!important;
    color:  #2F80EF!important;
    border: none!important;
    box-shadow: 0px 2px 8px rgba(193, 193, 217, 0.4) !important;
    border-radius: 4px!important;
}


.btn-default img{ margin-right: 5px }
.btn-primary{
    color: #fff !important;
    background-color: #2F80ED !important;
    border-color: #2F80ED !important;
}

.btn-cancel{
    color: #fff !important;
    background-color: #e24e13 !important;
    border-color: #e24e13 !important;
}

.btn-primary-group {
    color:  #74788D !important;
    background-color: #fff !important;
    border-color:#74788D !important;
}


.btn-outline-primary{
    color: #2F80ED !important;
    background-color: transparent;
    border-color:#2F80ED !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #2F80ED !important;
    border-color: #2F80ED !important;
}

.btn-gray{
    color: #000 !important;
    background-color: #ECECEC !important;
    border-color: #ECECEC !important;
}

.btn-outline-gray{
    color: #ECECEC !important;
    background-color: transparent;
    border-color:#ECECEC !important;
}

.btn-outline-gray:hover {
    color: #fff !important;;
    background-color: #ECECEC !important;
    border-color: #ECECEC !important;
}

.btn-large{
    padding: 11px 58px !important;
}

.btn-cirle-dash {
    display: inline-block!important;
    border-radius: 60px!important;
    padding: 0.5em 0.6em!important;
    background: #FFFFFF!important;
    border: 1px dashed rgba(140, 150, 181, 0.7)!important; 
}

.btn-cirle-dash:hover { border:  1px solid  rgba(140, 150, 181, 0.7)!important }


.btn-cirle-avatar{ cursor: pointer }

.btn-cirle-avatar:focus,.btn-cirle-avatar:active:hover{
    outline-color: red;
    border: 1px dashed rgba(140, 150, 181, 0.7)!important; 
}

.close-avatar {
    position: absolute;
    margin-left: 21px;
    margin-top: -6px;
}

.close-avatar  button{
    border:none;
    background-color:transparent;
    outline:none;
}

.close-avatar  img{ height: 7px }


.close-avatar .btn-cirle{
    display: inline-block!important;
    border-radius: 60px!important;
    background: #333333de;
    color: #fff;
    font-size: 12px;
}

.close-avatar .btn-cirle:hover{ background: #333333 }
.close-div{ border-left: 1px solid #ECECF5 }
.btn-icon .template{ margin-right: 15px }
.navbar-header{ border-bottom: 1px solid #ECECF5 }
.navbar-brand-box{
    padding: 0!important;
}
.navbar-brand-box, .vertical-menu{ border-right: 1px solid #ECECF5 }
.page-content { background: #F8F8FB !important }
.flex-row .col-lg-4 p{ color: #74788D }
.page-content-grey {
    background: #F8F8FB !important;
    padding-right: 0px !important;
}

.back-to-list{
    font-size: 12px!important;
    font-weight: 400!important;
    font-family: 'Poppins';
    line-height: 18px!important;
    color: #A6B0CF!important;
}

.request-kanban-header {
    height: 64px;
    background: #FFF;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
    margin: 0 !important;
    padding: 0 !important;
}

//on-hover buttons
.highlight-btn-blue:hover{
    background-color: #357eeb!important;
    color: white!important;
    
    img{
        filter: brightness(0) invert(1);    //change color to white
    }
}

.highlight-btn-white:hover{
    background-color: white!important;
    color: #878faa!important;
    border: 1px solid #ebebf4!important;

    img{
        filter: brightness(1) invert(1);    //change color to black
        opacity: 0.3;
    }
}

.react-kanban-board {
    /* wrapper */
    height: 100px;
    background-color: red!important;
}

// HEADER - Search Bar ------------------------------------------------
.app-search .form-control {
    background-color: white!important;
    border: 1px solid #E7EBF3!important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05)!important;
    border-radius: 4px!important;
    width: 350px;
}

.dropdown-filter{
    width: 580px;
    left: auto!important;
    right: -238px!important;
}

.filter-container{
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    font-family: 'Poppins';
    
    .filter-header{
        background: linear-gradient(to right, #2F80ED, #9198e5);
        color: white;
        padding: 12px;
    }

    .filter-body{
        padding: 12px;
        font-size: 12px;

        .filter-select-field{
            font-weight: 500;
            color: #74788D;
        }

        .filter-select-daterange{
            font-weight: 500;
            color: #74788D;
        }

        .filter-field-columns{
            width: 100%;

            .filter-icons-container{
                align-self: center;

                .filter-icons{
                    font-size: 14px;
                    color: #495057;
                    background: #F6F7FA;
                    border-radius: 4px;
                    padding: 12px;
                }
            }

            .filter-date-container input{
                background: #f6f7fa;
                border: none;
                padding: 10px;
                border-radius: 2px;
                margin-right: 2px;
                width: 180px;
            }
        }
    }

    .filter-footer{
        font-size: 12px;
        padding: 12px 0;

        .filter-clear{ color: #EB5757; cursor: pointer;}
        .filter-cancel{ color: #A2ADC3; cursor: pointer;}
        .filter-apply{ color: #2F80ED; cursor: pointer;}
    }
}

.filter-dropdown{
    position: absolute!important;
    top: 3px;
    right: 8px;

    i{ 
        cursor: pointer;
        font-size: 22px;
     }
}


.add-request-btn {
    background: #2F80ED;
    border-radius: 3px;
    width: 134px;
    height: 35px;
}

.kWYwZZ { background-color: #FFF !important };
.kanban-container { background-color: #F8F8FB };
.sc-AxmLO.kWYwZZ { border-radius: 8px !important };
.dropdown-delete{ color: #EF6473!important };
.dropdown-delete .fa-trash{ margin-right: 5px };
.task-card .date{ color: #A6B0CF; margin-top: 10px };
.task-card-calendar-ico { margin-right: 2px };

.task-card {
    background: #FFFFFF;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.06);
    position: relative;

    .card-actual-estimate{
        img{cursor: pointer}
        .time-1{
            // cursor: pointer;
            color: #2f80ed;
        }
        // .time-2{ cursor: pointer }

        .timer-picker-div{
            .timer-picker{
                overflow: hidden!important;
                left: -75px;
            }
        }
    }
}

div[data-rbd-droppable-id="board-droppable"] > div{
    .float-right.dropdown{
        margin-right: 20px;
    }
    .float-right.task-dropdown{
        margin-right: 0px;
    }
}

.card-subtasks{
    position: absolute;
    right: 25px;
    bottom: 50px;
    color: #74788D;
}
.card-actual-estimate{
    position: absolute;
    right: 25px;
    bottom: 22px;
}

.task-card .date{ color: #A6B0CF }

.task-card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #343A40;
}

.task-card-company {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #74788D;
}

.task-card-calendar-ico {
    margin-right: 2px;
}

.task-card-due-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #495057;
}

.task-card-preloader{ text-align: center }

.request-form-container {
    position: absolute;
    width: 812px;
    height: 850px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    border: 1px solid #ECECF5;
    box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.teams.form-container, .members.form-container{
    overflow: hidden;
}

.request-form-header {
    height: 58px;
    padding: 14 20 14 20 !important;
    margin: 0 !important;
}

.mark-done-btn {
    position: absolute;
    width: 150px;
    height: 30px;
    left: 26px;
    top: 14px;
    background: #FFFFFF;
    border: 1px solid rgba(140, 150, 181, 0.7);
    border-radius: 4px;
}
// END : Request - Kanban

.my-auto{
    margin-top: auto;
   margin-bottom: auto;
}

textarea{ overflow: hidden!important }
.required-ast{
    font-size: 14px;
    color: red;
}

.error-message{
    margin-left: 5px;
    font-size: 10px;
    background: #d82f2f !important;
    padding: 10px !important;
    border-radius: 0 !important;
    color: white;
    top: -7px;
    right: 250px;
    position: absolute;
    display: inline-block !important;
    box-shadow: 1px 1px 1px #aaaaaa;
    margin-top: 10px;
}

.error-message:before{ 
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 10px;
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #d82f2f;
  }

.page-content{
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.header-title{
    margin-top: -23px!important;
    background: #FFFFFF;
    border-bottom: 1px solid #ECECF5;
    padding: 15px;
}

.header-title-2{
    margin-top: -23px!important;
    background: #FFFFFF;
    border-bottom: 1px solid #ECECF5;
    padding: 15px 15px 0px 15px;
}

.page-title-box{ position: relative }
.page-title-box h2{
    margin: 0;
    position: absolute;               
    top: 50%;                        
    transform: translate(0, -50%);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
}


.container-body{
    padding: 15px;
    padding-right: 0px!important;
    padding-left: 0px!important;
}

.white-bg{ background: #fff }
.ticket-details {
    position: fixed;
    z-index: 4;
    right: 0;
    top: 69px;
    bottom: 0;
    min-width: calc(100% - 600px);
    max-width: calc(100% - 600px);
    border: 1px solid #ECECF5;
    box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
    transition: opacity 1s ease-out;
}

.picker-save-btn{
    padding: 2px 10px!important;
    color: #d82f2f !important;
    background-color: #F8F8FB;
    border-color: #d82f2f !important;
}
.picker-cancel-btn{
    margin-right: 10px;
    padding: 2px 10px!important;
}

// table css

// .MyTable div[data-test="datatable-info"] {
//    // background-color: yellow!important;
//     border: 1px solid #E6E7EF;
// }

.MyTable{
    padding-left: 12px;
    padding-right: 12px;
}

.MyTable thead[data-test="table-foot"] { display: none !important  }

 .MyTable div[data-test="datatable-select"] select{
    display: inline-block !important;
    width: auto !important;
 }


 .MyTable tbody[data-test="table-body"] tr td{
    vertical-align: middle !important;
    color: #495057;
    font-weight: 400;
    font-size: 13px;
 }

// Showing ... entries container
.dataTables_info{ font-weight: 500 }

.create-company{
    cursor: pointer;
    :hover{
        color: #2F80ED;
    }
}

.card-header{
    height: 53px!important;
    display: flex!important;
    align-items: center!important;
    letter-spacing: 0.01em!important;
    background: none!important;
    border-bottom: 1px solid #E6E7EF!important;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #8A8D9E;
}

.change-data {
    background-color: #fff;
    border: 1px double #eee;
    color: rgb(41, 146, 208);
    padding: 20px 10px 25px 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10px;
    cursor: pointer;
}

.ticket-details {

    .card-body {
    padding: 0px !important;
    position: relative;
    height: 100vh;

        .request-form-body{
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            
            .request-details{
                height: 100%;
                overflow: auto;
                overflow-x: hidden;
            }
        }
    }
}

.board-width.card-body{
    width: 400px;
    max-width: 400px;
}

.card-due-Today{ border-top: 5px solid rgba(242, 153, 74, 0.3) }
.card-due-Tomorrow{ border-top: 5px solid rgba(230, 180, 30, 0.3) }
.card-due-PastDue{ border-top: 5px solid rgba(235, 87, 87, 0.3) }
.card-due-Date{ border-top: 5px solid rgba(87, 235, 88, 0.3) }

.ticket-details{
    button{
        font-size: 10px;
        padding: 5px 8px;
    }

    .btn-default{
        font-size: 10px!important;
    }
}


.ticket-details .p-tag {
    line-height: 19px;  
    letter-spacing: 0.01em;
    color: #8A8D9E;
}

.ticket-details .title-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
    color: #8890AB;
    // letter-spacing: 0.01em;
    // color: #8A8D9E;
}

.ticket-details .title-2 {
    font-style: normal;
    margin-left: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    color: #495057;
    // letter-spacing: 0.01em;
    // color: #373B53;
}

.ticket-details .download-link { color: #F15A23 !important }


.ticket-details .card-details {
    padding-bottom: 10px;
    padding-right: 10px;
}

.ticket-details .card-details::-webkit-scrollbar { width: 5px }

.ticket-details .card-details::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
.ticket-details .card-details::-webkit-scrollbar-corner {
    background-color: transparent;
}

.ticket-details .card-details::-webkit-scrollbar-thumb{
    background-color: #E6E7EF;
    border-radius: 10px;
}

.form-input{
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
    border: none;
}

.form-input.request-form{
    min-height: 135px;
}

.form-input:active, .form-input:focus{
    border: none!important;
    outline: none!important;
}


.form-input::placeholder{ color: #AFB6CB }

.request-input, .request-input::placeholder{
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    
}
.request-input { color: #495057 !important }

.border-spacer{
    min-height: 2px;
    width: 100%;
    border-bottom: 1px solid #ECECF5;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cursor-pointer{ cursor: pointer }

.assignee-scroll{
    min-height: 100px;
    position: absolute;
    overflow-y: auto;
    max-height: 150px;
    border: 1px solid #ECECF5;
    border-radius: 5px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 5px;
    z-index: 9;
    background: #fff;
    max-width: 260px;
    width: max-content;
}

.assignee-scroll.request-assignee-qa{
    right: 0px;
    display: flex;
    list-style: none;
    flex-direction: column;
}

.assignee-scroll-list {
  font-size: 14px;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
}

.assignee-scroll-list:hover { background: #f4f4f4 }
.assignee-scroll-list small{ color: #6f7782 }

.form-select, .form-select:active{
    border: 0 !important;
    font-size: 14px!important;
    line-height: 24px!important;
    color: #AFB6CB!important;
}

.country-select .form-select{
    margin-top: -8px;
    margin-left: -8px!important;
}

 
// select2
.react-select-container { background-color: 'red' }
.react-select__menu { height: 100vh }
[class^='select2'] { border: none !important }
.css-yk16xz-control{ border: none !important }
.css-1okebmr-indicatorSeparator { background-color: none !important }
// end select2

.form-control{ border: none !important }
.note-subject{ padding: 0px!important }
.add-task, .task-author{ padding-left: 2px }
.add-task{

    .template{
        font-size: 14px!important;
        font-weight: 400!important;
    }
}
.form-control::placeholder{
    color: #AFB6CB!important;
    font-size: 12px;
    line-height: 24px;
}
.form-control-date{
    padding: 0!important;
    margin-top: -5px;
}
.task-author{
    background: #F8F8FB;
    border-bottom: 1px solid #ECECF5;
    padding: 10px 0px;
    padding-right: 10px;
    margin-left: 0px!important;
}

.task-author .author{
    font-weight: 500;
    font-size: 0.8vw;
    line-height: 19px;
    color: #495057;
    margin-left: 12px;
}

.task-author .author-date{
    font-size: 13px;
    line-height: 19px;
    color: #8C96B5;
    padding-top: 4px;
}

.card-bottom{
    background: #F8F8FB;
    // border: 1px solid #ECECF5;
}

.actual-time{
    font-size: 16px;
    line-height: 24px;
}

.actual-time .time-1{
    color: #2F80ED;
    cursor: pointer;
}

.actual-time .time-2{
    color: #495057;
    cursor: pointer;
}

.actual-time img{
    margin-top: -3px;
}

.default-cursor {
    cursor: default !important;
}


.property-info{
    position: absolute;
    right: 0px;
    top: 5px;
    background: white;
    padding: 0px 2px;
    color: #2f80ed;
    font-size: 15px;
    cursor: pointer;
}

.property-info.value-select{
    top: 12px;
}

.property-header{
    border-bottom: 1px solid #b9b9b9;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins';
    padding: 15px 0px;
}

.timer-picker{
    position: absolute;
    // left: 0;
    margin-top: 5px;
    border: 1px solid #f4f4f4;
    width: 200px;
    background: #f4f4f4;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.06);
    color: #8C96B5;
    font-size: 12px;
    z-index: 9;
    cursor: default;
}

.timer-picker:before{
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid #f4f4f4;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
}

.timer-picker input{
    border: 1px solid #e2e6ec!important;
}

.timer-picker .footer-content{
    margin-top: 10px;
}

.timer-picker .timer-title, .add-task{
    padding: 5px 15px 5px 15px;
}

.timer-picker .row{
    background: #fff;
    padding: 10px;
}


// .timer-picker:not(:focus) {
//    // display: none ;
// }

// .actual-time-and-estimate:hover ~ .timer-picker {
//     background: red!important;
// }

// .timer-picker:focus-within div {
//   // background: red!important;
// }


.actual-time-and-estimate:focus {
    display: none!important;
  }




  .fields-div {
    position: relative;
    top: 0; 
    display: none;
}

#hello-label:focus ~ .fields-div {
    display: block;
}

// .card-bottom-commit{
//     // font-size: 13px;
//     // line-height: 19px;
//     // color: #8C96B5;
//     // border: 1px solid #ECECF5;
//     // border-radius: 4px;
// }

.card-bottom-commit{
    position: relative;
    padding-bottom: 50px;
}

.container-div {
    display: flex;
}

.container-div > div {
    height: auto;
}

.left-div {
    width: auto;
    padding-right: 13px;
    padding-top: 10px;
}

.right-div {
    width: 100%;
}




// editor css
.rdw-editor-main {
    height: 50px!important; 
    overflow-y: hidden;
}

// .rdw-editor-main:hover{
//     min-height: 120px!important; 
//     overflow-y: auto!important;
// }

// .rdw-editor-toolbar  {
//     display: none;
// }


// .rdw-editor-main:hover ~ .rdw-editor-toolbar{
//     display: none !important;
// }

.ql-snow .ql-stroke {
    stroke: #8C96B5!important;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    stroke: #8C96B5!important;
}


.rdw-editor-toolbar{
    display: none !important;
}


.toogle-flex{
    display: flex !important;
}

.rdw-editor-main-add-class{
    height: 200px!important; 
    overflow-y: auto!important;
}




.toogle-show{
    display: block !important;
}

.toogle-hide{
    display: none !important;
}
// end editor css

.btn-comment{
    padding-top: 55px;
    z-index: 9999;
    position: absolute;
    right: 5px;
    top: 42px;
}

.btn-comment-btn{
    padding: 5px 15px!important;
    position: absolute;
    right: 8px;
    bottom: 5px;
}

.btn-comment .btn-comment-btn {
    background-color: #636D91 !important;
}

.div-followers{
    position: absolute;
    right: 10px;
    bottom: 2px;
}

.div-followers .title{
    margin-right : 10px;
    font-size: 13px;
    line-height: 19px;
    color: #8C96B5;
}


.subtask-div-container{
    
    border-bottom: 1px solid #ececf5;

}

.subtask-div{
    border-top: 1px solid #ececf5;
    display: flex;
    flex-flow: wrap row;
}


.subtask-div .row-1{
    width: 50px;
}

.subtask-div .row-2{
    flex: 1;
    align-items: center;
    display: flex;
}

.subtask-div .row-3{
    width: 100px;
}




.subtask-div textarea{
    resize: none;
    overflow: hidden;
    min-height: 25px;
    max-height: 300px;
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

::placeholder {
    color: #afb6cb;
    // font-style: italic;
}


.subtask-div textarea{
    resize: none;
    overflow: hidden;
    min-height: 25px;
    max-height: 300px;
    width: 100%;
    border: none;
    cursor: pointer;
    
}


textarea.subtask-div textarea:focus{
    cursor: default;
}

.subtask-div textarea:focus{
    background: none !important;
    outline: 0 !important;
}

.row-comments-list{
    padding: 15px 0px 5px 15px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    // overflow-wrap: break-word;
    // white-space: pre-wrap;
}

.row-comments-list .author{
    margin-left: 5px;
    font-weight: 500;
}

.row-comments-list .date{
    color: #6f7782;
    display: inline;
    font-size: 11px;
    margin-left: 8px;
    white-space: nowrap;
}

.row-comments-container{
    box-shadow: 0px 8px 10px 3px #e9e9e9;
    border-radius: 10px;

    .row-comments-list {
        .delete{ cursor: pointer };
        .body{ margin: 4px 10px 9px 38px }
    }
    
    .body{ 
        p img{ width: 100% };
    };
}

.comment-input-section{
    position: relative;
}

.row-comments-list .delete i:hover{ color: #aaaaaa };
.image-attachment{ margin-left: 33px };

.image-attachment img{
    margin-right: 10px;
    border: 1px solid #e6e7ef;
    padding: 5px;
    max-height: 400px;
    width: 100%;
}


.text-editor {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
}

.email{
    .ql-editor{
        min-height: 250px;
        padding: 12px 0px;
    }

    .ql-container.ql-snow{
        border: none!important;
    }
    .ql-editor.ql-blank::before{
        left: 0px;
    }
}

.email-seen{
    font-weight: 500;
}

.email-btn{
    padding: 8px 30px!important;
}

.email-cont{
    padding: 15px 20px;
}

// .react-kanban-column > div:nth-of-type(2) {
//     max-height: 500px !important;
//     overflow-y: scroll;
//     background: red;
//   }

// kanban
.hbVbBu {
    height: 80vh;
    overflow-x: auto;
}

.hbVbBu::-webkit-scrollbar {
    height: 10px;
    background-color: #a6b0cf8f;
}

.hbVbBu::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}


.hbVbBu::-webkit-scrollbar-thumb{
    background-color: #E6E7EF;
    border-radius: 10px;
}

.scrollbar-container{
    max-height: 70vh;
    overflow-y: auto;
}

// div[data-rbd-droppable-id="1"]::-webkit-scrollbar {
//     width: 10px;
//     background-color: #E6E7EF;
// }


// div[data-rbd-droppable-id="1"]::-webkit-scrollbar-button {
//     width: 0;
//     height: 0;
//     display: none;
// }


// div[data-rbd-droppable-id="1"]::-webkit-scrollbar-button {
//     width: 0;
//     height: 0;
//     display: none;
// }


// div[data-rbd-droppable-id="1"]::-webkit-scrollbar-thumb{
//     background-color: #a6b0cf8f;
//     border-radius: 10px;
// }



// end kanban

.ql-container.ql-snow {
    border: 1px solid #ECECF5 !important;
    overflow-y:auto;
}

.ql-toolbar.ql-snow {
    border: 1px solid #ECECF5;
}

.loader-board{
    background: #FFFFFF;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.06);
    padding: 24px !important;
    
}

// contact form css

.container-profile{
    display: flex;
}

.container-profile .row-1{
    padding-right: 7px;
}

.container-profile .row-2{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
}

.container-profile .row-2 p{
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #74788D;
}

.contact-form{
    position: fixed;
    bottom: -2px;
    right: 20px;
    width: 550px;
    border: 1px solid #ECECF5;
    box-shadow: 0px 6px 20px rgba(63, 70, 103, 0.08);
    background: #FFFFFF;
    z-index: 999;
}

.property-information-form{ 
    right: 650px;
}

.contact-form.website-form{
    width: 1062px;
}

.contact-form.property-details-form{
    width: 546px;
    right: 827px;
    // padding-bottom: 50px;
}

.contact-form input::placeholder{
    font-size: 13px;
    line-height: 19px;
    color: #A6B0CF;
}

.contact-form .form-header{ 
    background: linear-gradient(90deg, #2F80ED 0%, #797FFF 145.91%);
    border: 1px solid #ECECF5;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
}

.contact-form .form-header .row-1{ 
    flex: 1;
    padding-right: 20px;
}

.contact-form > .form-body{
    margin: 10px 31px;
    overflow: auto;
    max-height: 80vh;
}

.contact-form > .form-body.add-request-form{
    margin: 10px 0;
    padding: 0px 30px;
}


.tag-form > .form-body {
    height: 35vh;
}

.addtag-form{
    width: 100%;
}

.css-4ljt47-MenuList{
    max-height: 145px!important;
}

.contact-form > .form-body::-webkit-scrollbar {
    width: 5px;
}

.contact-form > .form-body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
.contact-form > .form-body::-webkit-scrollbar-corner {
    background-color: transparent;
}

.contact-form > .form-bodys::-webkit-scrollbar-thumb{
    background-color: #E6E7EF;
    border-radius: 10px;
}

.contact-group{
    border-bottom: 1px solid #ECECF5;
    display: flex;
    flex-flow: wrap row;
    align-items: baseline;
    padding: 2px;
}

.contact-group.task-template{
   padding-bottom: 10px;
}


.contact-group-checkbox {
    border-bottom: none !important;
}



.contact-group-border{
    border-bottom: 1px solid #ECECF5;
}

.contact-group > .row-1{
    width: 120px;
}

.contact-group > .row-2{
    flex: 1;
    // margin-left: 12px;
    // font-weight: 500;
    // font-size: 13px;
    // line-height: 19px;
    color: #495057;
}

.contact-group-checkbox > .row-3{
    display: flex;
    flex-direction: row;
}

.contact-group-checkbox > .row-3 div{
    display: flex;
    width: 60px;
    padding-top: 10px;
}

.col-4 button img {
    // height: 10px;
    margin-right: 0;
}

.col-7 button img {
    height: 12px;
}

.col-1 button img {
    height: 10px;
}


.contact-group > .row-2.title-only{
    margin-left: 21px !important;
}

.form-body .form-action {
    text-align: right;
    padding: 25px 25px;
}

.contact-info .form-action {
    text-align: right;
    padding-top: 42px;
}


body.minimize-form  .contact-form > .form-body{
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
    
} 

body.minimize-form  .contact-form{
   width: 400px;
    
} 

//contact details

p.title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3F4667;
}

div.title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3F4667;
}

.basic-contact .row-2{
    font-weight: 500;
    font-size: 16.25px;
    line-height: 24px;
    color: #495057;
}

.basic-contact > .row-2 > .company{
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #2F80ED;
}


.row-contacts{
    background: #FFFFFF;
    margin-top: -58px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
}

.row-contacts .div-1{
    border-right: 1px solid #ECECF5 !important;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726) !important;
    padding: 20px;
}
.row-contacts  .action-btn{
    margin: 10px 0px;
    // line-height: 4;
}

.company-details-div {
    border-top: 1.5px solid #F5F5FA;
    border-bottom: 1.5px solid #F5F5FA;
    padding: 25px 0px;
}
.company-details-div .logo{
    width: 120px;
    margin-right: 10px;
}

.company-details-div .name{
    font-style: normal;
    font-weight: 500;
    font-size: 16.25px;
    line-height: 24px;
    color: #495057;
}
.profile-type{
    background: rgba(47, 128, 237, 0.12);
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 11px;
    color: #2F80ED;
    font-weight: 500;
    font-family: 'Poppins';
    position: absolute;
    top: 26px;
    right: 10px;
}

.contact-info {
    padding: 30px 0px;
    border-bottom: 1.5px solid #F5F5FA;
}

.other-info{
    padding: 30px 0px;
}
.other-info .result{
    font-weight: 500;
    font-size: 16.25px;
    line-height: 24px;
}
.referrer-info{
    border-top: 1.5px solid #F5F5FA;
    padding-top: 20px;
    margin-top: 10px;
}

.rating img{
    margin-right: 5px;
}
        
.row-contacts .div-2{
    margin-top: 20px;
    padding-left: 0px !important;
    padding-right: 0px;
}

.nav-tabs-custom {
    border-bottom: 1px solid #ECECF5!important;
    background: #FFFFFF;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;   
    margin-left: 10px;
}

.nav-tabs-custom a.nav-link {
    padding-left: 0px !important;
    padding-right: 0px !important;
    color: #A6B0CF;  
}

.nav-tabs-custom a.active.nav-link { 
    color: #2F80ED !important;
    border-bottom: 3px solid #2F80ED;
    padding-bottom: 4px;
}

.nav-tabs-custom a.nav-link{ 
    font-weight: 400!important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.navlink-second{ margin-left: 40px }
.nav-tabs-custom .nav-item .nav-link::after { background: #2F80ED !important }
ul.nav-tabs-custom.nav.nav-tabs {
    // padding: 0px 20px !important;
    margin: 0px 0px!important;
    
    //scroll horizontally
    flex-wrap: unset;
    white-space: nowrap;
    overflow-y: hidden;
}

.request-task{
    padding: 20px;
    margin: 20px 26px 0 32px;
    background: #fff;
}
 
.request-task .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3F4667;
}

.basic-contact{
    margin-bottom: 1.5rem;
    
    .address{
        font-weight: 400;
        font-size: 13px;
        color: #2F80ED;
        line-height: 19.5px;
    }
}

textarea.task-details {
    resize: none;
    overflow: hidden;
    min-height: 200px;
    max-height: 300px;
    width: 100%;
    border: none;
}

textarea.task-details:focus{
    background: none !important;
    outline: 0 !important;
}

.nav-contact {
    border-bottom: none !important;
    background: #FFFFFF;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;   
    margin-left: 10px;
}

.nav-contact a.nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
    color: #A6B0CF;  
}

.nav-contact .nav-item {
    position: relative;
    color: #343a40;
    width: 50%;
    margin-left: 0px;
}

ul.nav-contact.nav.nav-tabs {
    padding-left: 0px !important;
    margin-left: 0px !important;
}

.nav-contact .nav-item {
    display: flex;
    justify-content: center;
}

.form-body-contact{
    min-height: 430px;
    margin: 0px!important;
    padding: 0px!important;
}

.change-password-form .contact-group {
    margin-top: 10px;
}

.change-password.footer-action {
    border-top: 1px solid #ECECF5;
    padding-top: 31px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8890AB;
    display: flex;
    // align-items: flex-start;
    justify-content: center;
}

.contact-form .footer-action{
    border-top: 1px solid #ECECF5;
    padding-top: 31px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8890AB;
}

.recipient{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #A6B0CF;
}

// end contact form css

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2F80ED !important;
    border-color: #2F80ED !important;
}

.eror-fields{
    color: #dc3545;
    font-weight: 100;
    margin-left: 10px;
}

// notification css
.display-none{
    display: none;
}
/* The snackbar - position it at the bottom and in the middle of the screen */
.notification-div {
    visibility: hidden; /* Hidden by default. Visible on click */
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 321px;
    height: 113px;
    background: linear-gradient(270deg, #41D48F -9.34%, #2CD8AE 122.25%);
    color: #fff;
    border: 1px solid #E6E7EF;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 999991;
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  .notification-div.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5s;
    animation: fadein 0.5s, fadeout 0.5s 5s;
    z-index: 999991;
  }
  
.notification-div img{
    width: 86px;
}
.notification-div .notification-details h5{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #fff;
}

.notification-div .notification-details p{
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #fff;
}

.flex-center{
    justify-content: center;
    display: flex;
    align-items: center;
}

// end notification css

// company style
h4.website-font{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
   color: #495057;
}

.new-card-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(238, 238, 243, 0.7);
    border: 1px dashed #DDDFEA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    padding: 20px 0px;
}

.new-card-div span{
    font-size: 13px;
    line-height: 19px;
    color: #A1A8BB;
}

.new-card-div img{
    width: 22px;
    height: 22px;
    margin-right: 20px;
}

.new-card-div:active{
    content: "";
    background: #f15a23;
    border: 2px dashed #f15a23;
    transition: all 3s ease-out;
}

// ripple
.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #00000047 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}
//end ripple

.btn-group, .btn-group-vertical {
    background: transparent !important;
    border: none !important;
}

.btn-group, .btn-group-vertical:active {
    background: transparent !important;
    border: none !important;
}

.select-type-content{
    position: relative;
}

.select-type{
    width: 300px;
    width: 208px;
    background: #FFFFFF;
    border: 1px solid #ECECF5;
    box-shadow: 0px 3px 10px rgba(63, 73, 100, 0.1);
    border-radius: 2px;
    z-index: 10;
    cursor: pointer;
    margin-top: -175px;
    position: absolute;
    left: 40%;
}

.select-type-2{
    margin-top: -135px;
}

.select-type-list{
    width: 100%;
}

.select-type::after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    width: 0px;
    left: 44%;
    height: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    background: transparent;
    margin-bottom: -20px;
}

.select-type > .select-type-list ul{
   list-style: none;
   padding-left: 0px;
   
}

.select-type > .select-type-list ul li{
    padding: 7px 10px;
}

.select-type > .select-type-list ul li:hover{
    background: rgba(199, 204, 219, 0.2);
}
.select-type-title{
    color: #8890AB;
    font-weight: 100;
    padding: 7px 10px;
}
// end company css

.br {
    border-radius: 0px !important;
    border-right: 1px solid #EFF2F7 !important;
}

.address-header {
    font-weight: 600;
    color: #495057;
    font-size: 13px;
}

.country-select {
    width: 101% !important;
}

// .country-select.form-control {
//     margin-left: -30px !important;
//     padding: 0 !important;
//     width: 101%;
// }

// .row-email{
//     //height: calc(100vh - 250px);
// }

.row-email .no-email{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    background: #F8F8FB;
}

.row-email .table-col{
    background: #fff;
}

.row-email .no-text{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #495057;
}

.nopadding{
    padding: 0px !important;
}

.email-details{
    padding: 10px;
    overflow: overlay;
    overflow-x: hidden;
}

.email-details .email-card{
    background: #FFFFFF;
    border: 1px solid #ECECF5;
    border-radius: 4px;
   // padding: 12px;
}

.subject-container{
    display: flex;
    flex-direction: row;
    align-items: center;

    .inbox-seen{
        color: #3680ed;
        font-size: 5px;
        border-radius: 30px;
        padding: 0;
        box-shadow: 0px -1px 5px 1px #3680ed;
        margin-right: 15px;
        margin-left: -10px;
    }
}

.request-status {
    .status-icon{
        font-size: 5px;
        border-radius: 30px;
        padding: 0;
        margin-right: 10px;
    }
    .status-icon.status-1{      // New Request
        color: #3680ed;
        box-shadow: 0px -1px 5px 1px #3680ed;
    }
    .status-icon.status-2{      // Proposal Sent
        color: #9B51E0;
        box-shadow: 0px -1px 5px 1px #9B51E0;
    }
    .status-icon.status-3{      // Awaiting Commencement
        color: #2F80ED;
        box-shadow: 0px -1px 5px 1px #2F80ED;
    }
    .status-icon.status-4{      // In Progress
        color: #F2C94C;
        box-shadow: 0px -1px 5px 1px #F2C94C;
    }
    .status-icon.status-5{      // In Review
        color: #F2C94C;
        box-shadow: 0px -1px 5px 1px #F2C94C;
    }
    .status-icon.status-6{      // On-Hold
        color: #828282;
        box-shadow: 0px -1px 5px 1px #828282;
    }
    .status-icon.status-7{      // Complete - Awaiting Client Confirmation
        color: #16c79a;
        box-shadow: 0px -1px 5px 1px #16c79a;
    }
    .status-icon.status-8{      // Archive
        color: #ed3636;
        box-shadow: 0px -1px 5px 1px #ed3636;
    }
}

button.btn-inbox{
    border-radius: 50px !important;
}

button.btn-inbox:active{
    background: rgba(47, 128, 237, 0.12) !important;
    border-radius: 50px !important;
}

.btn-circle-email{
    border-radius: 50% !important;
    background: #FFFFFF !important;
    border: 1px solid #ECECF5 !important;
    padding: 10px 12px !important;
}

.btn-circle-email img{
    height: 16px;
    width: 16px;
}

.btn-circle-email {
    margin-left: 8px !important;
}


.top-action{
    display: flex;
    justify-content: space-between;
    margin: 20px 20px;
    
}

.top-action .top-action-btn{
    width: 250px;
    text-align: right;
}


.top-action-title{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #495057;
}


.email-card .email-head{
    display: flex;
    justify-content: space-between;
    border: 1px solid #ECECF5;
    padding: 30px;
}

.email-card .email-head .avatar-name{
    display: flex;
    // flex-flow: wrap row;
    align-items: center;
}

.email-card .email-head .date-time{
    color: #A6B0CF;
    width: 230px;
    text-align: right;
    align-self: center;
    font-size: 12px;
}

.email-card .email-head .email-type{
    display: flex;
    flex-direction: column;
    place-items: flex-end;
}

.email-card .email-head .email-type-div{
    padding: 10px;
    border-radius: 25px;
}

.email-card .email-head .avatar-content{
    display: flex;
    flex-flow: wrap column;
    // word-break: break-word;
    padding-right: 10px;
    width: 200px;
}

.email-card .email-head .avatar-content h2{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
}

.column {
    float: left;
    width: 25%;
}
  
  /* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}

.email-card .email-content{
    padding: 30px;
    // overflow-y: auto;
    // max-height: 51vh;
    //border-right: 10px solid transparent;
}

.w100 {
    width: 100% !important;
}

.switch-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1;
    color: #fff;
}

.react-switch-bg {
    padding-right: 5px;
    padding-left: 5px;
}

.switch-input span{
    font-weight: 500;
    font-size: 16.25px;
    line-height: 24px;
    margin-right: 10px;
}

.other-text{
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #495057 !important;
}

// request board - droppable div
div[data-rbd-droppable-id] {
    max-height: 73vh;
    overflow-y:  auto;
    padding-bottom: 20px;

    ::-webkit-scrollbar { width: 5px }
    ::-webkit-scrollbar-thumb{
        background-color: #E6E7EF;
        border-radius: 10px;
        scroll-padding-right: 20px;
    }
}

div[data-rbd-droppable-id="board-droppable"] {
    max-height: calc(100vh - 160px)!important;
    overflow-y: hidden;
}
.selected-to{
    font-weight: 400;
    font-size: 12px;
    color: #A6B0CF;
    line-height: 18px;
}

.badge-due{
    background-color: white!important;
    color: #6c6c6c!important;
}

.due-Today{ color: #F2994A !important }
.due-Tomorrow{ color: #E6B41E !important }
.due-PastDue{ color: #EB5757 !important }

.no-scroll{ overflow: hidden }
.break-word{
    overflow-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 5px;
    padding-right: 2px;

    .btn{
        padding: 5px 5px;
        margin: 0!important;
    }
}

.share-description{
    color: #495057;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    padding: 10px 0px;
}

.referrer-container{ justify-content: center }
.referrer-total-container{
    justify-content: center;
    padding: 0px 28px;

    .total-earned, .total-payments, .life-time-value{
        margin: 20px 10px 0px 10px;
        position: relative;
        
        p{
            font-weight: 500;
            margin-bottom: 5px;
            font-family: 'Poppins';
            color: #74788D;
            font-size: 12px;
        }

        b{
            font-size: 18px;
            font-weight: 500;
        }
        
        i{
            position: absolute;
            right: 15px;
            top: 25px;
            background-color: rgba(47, 128, 237, 0.12);
            padding: 15px;
            border-radius: 100%;
            color: #2F80ED;
            font-size: 18px;
        }
    }

    @media (min-width: 768px){
        .total-earned, .total-payments, .life-time-value {
            flex: 0 0 30%;
            max-width: 30%;
        }
    }
}
.referrer-tier-container{
    justify-content: center;

    .tier-1{
        text-align-last: center;
        padding: 20px 50px;

        p{ 
            margin-bottom: 5px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 14px!important;
            color: #495057;
        }
    }

    .tier-2{
        text-align-last: center;
        padding: 20px 50px;

        p{
            margin-bottom: 5px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 14px!important;
            color: #495057;
        }
    }
}

.internal-notes{
    background: #FFFFFF;
    border: 1px solid #ECECF5;
    box-shadow: 0px 6px 20px rgba(63, 70, 103, 0.08);
    border-radius: 4px;
    padding-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.internal-notes .header{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 200px;
    align-items: center;
}

.internal-notes .footer-note{
    padding-right:  20px;
    padding-top:  20px;
    text-align: right;
}

.note-form{ align-self: end }
.note-container{ margin-top: 10px }
.btn-icon-only{
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #C7CCDB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.public-reply{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.row-email-details{
    height: 100%;
    // height: calc(100vh - 146px);
    width: 50%;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 20px;
}

.row-email-details:hover{
    // overflow: overlay;
    overflow-x: hidden;
}

.row-email-details::-webkit-scrollbar {
    width: 5px;
}

.row-email-details::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
.row-email-details::-webkit-scrollbar-corner {
    background-color: transparent;
}

.row-email-details::-webkit-scrollbar-thumb{
    background-color: #cccccc;
    // outline: 1px solid slategrey;
    border-radius: 10px;
}

.message-tooltip{
    font-size: 10px !important;
    opacity: 0.8!important;
    padding: 5px 10px!important;
    :hover { opacity: 1 !important }
}

.row-message-data{
    display: flex;
    width: 100%;
    padding: 10px;
}

.row-attachment{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93px;
}

.note-attachment-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 10px;
    height: 120px;
    overflow-x: auto;
}

.note-attachment-container { overflow-x: scroll; height: 110px; width: 650px; }
.note-attachment-container .uploads-wrap { display: flex; width: auto; }

.note-attachment-container .attachment-item {
    .row-attachment {
        width: 100%;
        min-height: 60px;
    }
}

.note-attachment-container .column-1{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.note-attachment-container .column-2 {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
}

.note-attachment-container .column-3{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.note-attachment-details {
    display: flex;
    flex-direction: row;
    flex: 3;
}

.note-attachment-preview {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: center;
    img {
        width: 40px;
        height: 40px;
    }
}

.propertyIcon{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 33px;
    margin-right: 52px;
    cursor: pointer;
}

.table-property{
    width: 100%;
}

.table-property tr{
    border-top: 1px solid #EFF2F7;
    border-bottom: 1px solid #EFF2F7;
}

.table-property td{
    padding: 12px 0px;
}

.inline-radio{
    display: flex;
    width: 220px;
    justify-content: center;
    padding: 7px 0px;
}

.profile-btn {
    margin-bottom: 13px;
    margin-right: 15px;
    height: 39px;
    width: 148px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left !important;
    padding: 0px 5px 0px 10px !important;
    color: #74788D;
}

.profile-status-column {
    display: flex;
    align-items: center;
    align-content: center;
}

// .profile-status {
//     // align-content: center;
//     // align-items: center;
//     // text-align: center;
// }

.status-circle-active {
    width: 7px;
    height: 7px;
    background: #1CC88A;
    border-radius: 200px;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 14px;
}

.status-circle-inactive {
    width: 7px;
    height: 7px;
    background: #d3220b;
    border-radius: 200px;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 14px;
}

.page-title-header{
    color: #495057;
    text-transform: uppercase;
    font-weight: 600;
}

// REACT-DATEPICKER -------------------------------------------------
.react-datepicker {
    font-family: 'Poppins';
    width: 328px;
    padding: 15px;
}

.padding-0 .react-datepicker{   //inbox > add request (due date)
    left: -68px;
}

.datepicker-timeonly .react-datepicker{
    width: 115px;
    left: -52px;
}
.react-datepicker__day--disabled{   //past dates
    color: #a3a3a3!important;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input{
    width: 115px;
    border: 1px solid #3680ed;
    border-radius: 5px;
    padding: 2px 10px;
}

.react-datepicker__month-container {
    float: unset;
}

//Datepicker header
.react-datepicker__header{
    background-color: white;
}

//Datepicker Header
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #2F80ED;
    font-weight: 500;
}

//Month navigator next
.react-datepicker__navigation--next {
    right: 30px;
    border-left-color: #ccc;
    border: solid #C9C9C9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(-45deg);
    padding: 0px!important;
}

//Month navigator previous
.react-datepicker__navigation--previous {
    left: 30px;
    border-left-color: #ccc;
    border: solid #C9C9C9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(135deg);
    padding: 0px!important;
}

//Month navigator
.react-datepicker__navigation{
    top: 30px
}

// Days in calendar
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 2.2rem;
    // padding: 5px;
}

//Days that are outside the month
.react-datepicker__day.react-datepicker__day--outside-month, .react-datepicker__day-name{
    color: #999999;   
}

//Days in calendar
.react-datepicker__day{
    color: #495057;
}

//Selected date from calendar
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
    background-color: rgba(47, 128, 237, 0.12);
    color: #2F80ED;
    font-weight: 600;
}

.datepicker-icon {
    width: 7px;
    height: 7px;
    margin-top: 5px;
    margin-right: 8px;
}

.datepicker-group {
    align-items: center;
    justify-content: center;
}

.datepicker { border: none !important }
.datepicker-container{ padding-left: 0px!important }
.padding-0 { padding: 0 !important }
.datepicker input {
    padding: 0;
    margin-top: -5px !important;
    border: 0;
}

.datepicker-icon {
    width: 13.09px;
    height: 13px;
}

.new-tag-circle-ok {
    position: absolute;
    top: -12px;
    left: 0;
    width: 20px; 
    height: 20px;
    text-align: center;
    color: #527ef5;
    font-size: 16px;
    cursor: pointer;
}

.new-tag-circle-clear {
    position: absolute;
    top: -12px;
    left: 0;
    width: 20px; 
    height: 20px;
    text-align: center;
    color: #d3220b;
    font-size: 16px;
    cursor: pointer;
}

.tag-assigned { color: #527ef5 }

.tag-option {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.tag-option .row i{ cursor: pointer }

.tags-list{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 480px;
    padding: 0 8px;
    // border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    &:focus-within {
      border: 1px solid #0052cc;
    }
    input {
      flex: 1;
      border: none;
      height: 46px;
      font-size: 14px;
      padding: 4px 0 0 0;
      &:focus {
        outline: transparent;
      }
    }
  }
  
#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.remove-tag{
    position: absolute;
    width: 8px;
    height: 8px;
    right: 8px;
    top: 12px;
}


.tag-title{
    padding-bottom: 20px;
    border-bottom: 1px solid #EFF2F7;
    margin-bottom: 15px;
}

.tag-list{
    padding: 0px 12px;

    .tag {
        width: 327px;
        height: 39px;
        display: flex;
        align-items: center;
        font-size: 13px;
        margin: 0 8px 8px 0;
        font-family: 'Poppins';
        font-weight: 500;
        line-height: 19.5px;
        border: 1px solid #ECECF5;
        box-sizing: border-box;
        border-radius: 3px;
    }
}

.tags-count{
    font-weight: 400;
    color: #2F80ED;
    float: right;
}

//SWAL
.swal2-close{
    top: 10px;
    right: 10px;
}

.swal-custom-cancel{
    color: #B0BAC5!important;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px!important;
    line-height: 21px;
    border-radius: 25px!important;
    padding: 10px 40px;
}

.swal-custom-confirm{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px!important;
    line-height: 21px;
    border-radius: 25px!important;
    padding: 10px 40px;
}

.swal-custom-content{
    display: block;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px!important;
    line-height: 23.8px;
    padding: 15px 15px;
}

.swal-custom-title{
    font-family: 'Poppins';
    font-weight: 500;
    font-style: 18px!important;
    line-height: 27px;
    color: #3F4667!important;
    margin-top: 18px;
}

.swal-custom-popup{
    width: 30em;
    padding: 3.25em 1.25em;
}

//add tag 
.new-tag-btn{
    box-shadow: 0px 2px 8px rgba(193, 193, 217, 0.4);
    border-radius: 4px;
    font-weight: 400!important;
    color: #2F80ED!important;
    font-family: 'Poppins';
    line-height: 21px!important;
    font-size: 14px!important;
}

.add-tag-btn{
    font-weight: 400!important;
    border-radius: 0px!important;
    color: #2F80ED!important;
    font-family: 'Poppins';
    line-height: 21px!important;
    font-size: 14px!important;
    width: 100%;
    border-bottom: 2px solid #3680ed!important;
}

.add-tag-form{
    margin: 10px 15px!important;
}

.phone-logs-form{
    width: 450px!important;
}

//change images to white color
.image-to-white{
    filter: brightness(0) invert(1);
}

// Email Attachment
.file-upload {
	position: relative;
	overflow: hidden;
	margin: 10px;
}
.file-upload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}

.attachment-title{
    font-size: 14px;
    font-weight: 400;
    color: #c5cbda;
    background: white;
    position: relative;
    left: 15px;
    z-index: 2;
    padding: 0px 8px;
}

.attachment-line{
    border-bottom: 1px solid #c5cbda;
    margin: 0px 25px;
    position: relative;
    z-index: 1;
    top: 12px;
}

.attachment{
    background: #ededed;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 0px;

    span{ margin-right: 10px }
    i{
        float: right;
        padding: 5px;
    }
} 


.YES{
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    background: rgba(79, 121, 250, 0.12);
    color: #599cff;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}

.NO{
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    background: rgba(166, 176, 207, 0.12);
    color: #828282;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}


// Subscriptions & Payments
.payment-invoice{ font-weight: 600 }
.subscription-name{ font-weight: 600 }
.Paid .fa-circle{ color: #1CC88A }
.paymentFailed .fa-circle{ color: #EB5757 }
.payment {
    .fa-circle{
        font-size: 6px;
        position: relative;
        top: -2px;
        left: -2px;
    }
}
.subscription{
    border-radius: 20px;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 11px;
}
.Current{ 
    color: #34C38F;
    background: rgba(52, 195, 143, 0.12);
}

.Canceled{
    color: #EB5757;
    background: hsla(0, 79%, 63%, 0.12);
}
.current{ 
    color: #34C38F;
    background: rgba(52, 195, 143, 0.12);
}

.canceled{
    color: #EB5757;
    background: hsla(0, 79%, 63%, 0.12);
}

.time-adjustments-modal.modal-dialog {
    width: 307px !important;
    min-height: 374px !important;
}

.time-adjustments-modal .modal-content {
    width: 307px !important;
    min-height: 374px !important;
    padding: 20px 26px 28px 28px !important;
}

.time-adjustments-modal .modal-content .modal-body {
    padding: 0 !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.time-adjustment-fields {
    display: flex;
    justify-content: space-between;
}

.time-adjustments-add-deduct {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.time-adjustment-buttons {
    display: flex;
    justify-content: space-between;
}

.time-adjustment-buttons .btn {
    width: 118px !important;
    height: 35px;
}

.time-adjustments-header {
    font-size: 18px;
    line-height: 27px;
    color: #495057;
    flex: 1;
    justify-content: center;
    flex-direction: row;
}

.time-adjustments-close {
    width: 14.5px;
    height: 14.5px;
}

.add-time {
    // margin-right: 7px;
    width: 118px !important;
    height: 35px;
    font-size: 13px !important;
    color: #2F80ED;
}

.deduct-time {
    // margin-right: 7px;
    width: 118px !important;
    height: 35px;
    font-size: 13px !important;
    color: #495057 !important;
}

.time-adjustment-label {
    width: 37px;
    height: 35px;
    background: rgba(144, 151, 176, 0.12);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
}

.time-adjustment-section {
    color: #8890AB;
    font-size: 12px;
    line-height: 18px;
}

.time-adjustment-input {
    width: 118px;
    height: 35px;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.time-adjustment-input .time-input {
    border: none !important;
    width: 50px;
    height: 33px;
    padding: 10px 12px 8px 12px;
    flex-grow: 1;
}

.time-adjustment-note {
    width: 252px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    overflow: hidden;
    min-height: 58px;
    max-height: 100px;
    border: none;
    min-height: 40px !important;
}

.time-adjustment-note:focus{
    background: none !important;
    outline: 0 !important;
}

.btn-icon #edit-time-adjustments-btn { border: none !important }

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
    display: flex !important;
    flex: 1;
    flex-direction: flex-start;
    align-items: center;
}

.dropdown-container .waves-effect {
    display: flex !important;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown-container .waves-effect {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.dropdown-menu-custom{
    min-width: 9rem!important;

    .dropdown-item-custom{ padding: 0.35rem 0.5rem!important }
}

.with-dropdown{
    display: flex!important;
    align-items: center;

    .right-icon{
        float: right;
        margin-left: -10px;
        filter: unset;
    }
    .right-icon:hover{
        filter: brightness(1) saturate(12) drop-shadow(0 0 0px #2F80ED)       //change image to blue and bolder
    }
}

.selected-menu{
    img{ filter: brightness(1) saturate(12) drop-shadow(0 0 0px #2F80ED) }    //change image to blue and bolder
}

.sidebar-submenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px;
    margin-bottom: 10px;
}

.sidebar-submenu-group { margin-top: 10px }
.sidebar-submenu-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #6A7187;
}

.sidebar-submenu-organisation {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #A6B0CF;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px!important;

    p { width: 170px }
}

.sidebar-submenu-organisation:hover {
    color: #2F80ED!important;
}

.sidebar-submenu-organisation a.active.nav-link { 
    color: #2F80ED !important;
    border-bottom: 3px solid #2F80ED;
    padding-bottom: 4px;
}

.sidebar-submenu-organisation a.nav-link{ 
    font-weight: 400!important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.sidebar-submenu-contractor {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6A7187;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px!important;
}

.sidebar-submenu-contractor a.active.nav-link { 
    color: #2F80ED !important;
    border-bottom: 3px solid #2F80ED;
    padding-bottom: 4px;
}

.sidebar-submenu-contractor a.nav-link{ 
    font-weight: 400!important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.sidebar-submenu-organisation-rectangle {
    width: 7px;
    height: 7px;
    background: #A6B0CF;
    border-radius: 2px;
    margin-right: 15px;
    margin-left: 5px;
}

.active-filter .sidebar-submenu-organisation-rectangle {
    background: #2F80ED !important;
}

.active-filter .sidebar-submenu-organisation {
    color: #2F80ED !important;
}

.sidebar-submenu-contractor > div {
// #side-menu > li.mm-show > div.sidebar-submenu > div:nth-child(2) > div > a > div {
    width: 18px !important;
    height: 18px !important;
    margin-right: 11px !important;
}

.sidebar-submenu-contractor > div > div {
// #side-menu > li.mm-show > div.sidebar-submenu > div:nth-child(2) > div > a > div > div {
    margin-right: 11px !important;
    width: 18px !important;
    height: 18px !important;
    background: rgba(85, 110, 230, 0.25098) !important;
}

.sidebar-submenu-contractor > div > div > div > span > span {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 8px !important;
    color: #556EE6 !important;
}

.sidebar-submenu-contractor {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6A7187;
}

.active-filter .sidebar-submenu-contractor span {
    color: #2F80ED !important;
}

//material-table style
.MTableToolbar-highlight-6{
    background-color: white!important;
    padding: 0px 50px!important;
    box-shadow: 0px -8px 14px -8px #dedede;
    // border-top: 2px solid #3680ed;
    position: fixed!important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.MTableToolbar-highlight-6{
    min-height: 58px!important;
}


@media (min-width: 600px){
    .MTableToolbar-highlight-6 {
        min-height: 64px!important;
    }
}
@media (min-width: 0px) and (orientation: landscape){
    .MTableToolbar-highlight-6 {
        min-height: 58px!important;
    }
}

.jss6{
    background-color: white!important;
    padding: 0px 50px!important;
    box-shadow: 0px -8px 14px -8px #dedede;
    // border-top: 2px solid #3680ed;
    position: fixed!important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.jss6{
    min-height: 58px!important;
}


@media (min-width: 600px){
    .jss6 {
        min-height: 64px!important;
    }
}
@media (min-width: 0px) and (orientation: landscape){
    .jss6 {
        min-height: 58px!important;
    }
}


.MuiToolbar-regular{
    min-height: 58px!important;
}

.MuiTypography-h6 {
    color: #2F80ED;
    font-size: 16px!important;
    font-family: 'Poppins'!important;
    font-weight: 500!important;
}

.material-action-container{
    font-size: 12px;
    font-weight: 500;
    padding: 0px 15px;
    display: flex;
    align-items: center;
}

.material-action-icon{
    font-size: 18px!important;
    color: #3680ed;
    padding-right: 8px;
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color:#3680ed!important;   
}

.MuiTableCell-head {
    font-weight: 600!important;
    line-height: 1.5rem;
    font-family: 'Poppins';
    font-size: 13px;
    color: #495057!important;
}

.contact-preloader-container{
    list-style-type: none;   

    li{
        margin: 10px 0px;
    }
}

.view-profile-btn{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px!important;
    padding: 0.30rem 0.5rem!important;
}

.view-profile-btn:hover{
    background: #3680ed;
    border: none;
    color: white!important;
}


//DATATABLE - Action button ------------------------------------
.action-btn.edit{
    background: white;
    color: #3680ed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px!important;
    padding: 0.30rem 0.5rem!important;
}

.action-btn.edit:hover{
    background: #3680ed;
    border: 1px solid white;
    color: white!important;
}

.action-btn.delete{
    background: white;
    color: #EB5757;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px!important;
    padding: 0.30rem 0.5rem!important;
}

.action-btn.delete:hover{
    background: #EB5757;
    color: white;
}

.action-btn.delete:hover{
    background: #EB5757;
    border: 1px solid white;
    color: white!important;
}


//DATATABLE - Member Name --------------------------------------
.member_name{ font-weight: 500 }

//DATATABLE - User level ---------------------------------------
.user_level_td{
    background: rgba(47, 128, 237, 0.180392);
    padding: 7px;
    border-radius: 4px;
    color: #2F80ED;
    font-weight: 500;
    font-size: 12px;
}

//DATATABE - Team ----------------------------------------------
.team_name{
    font-size: 13px;
    font-weight: 500;
    color: #2F80ED;
}
.team_members_count{
    color: #495057;
    font-size: 10px;
    font-weight: 400;
}


.requestor-companyname{
    margin-top: -2px;
    font-weight: 500;
    line-height: 15px;
}

.svg-custom img{
    filter: brightness(1) saturate(0.3);
    opacity: 0.5;
}

.svg-custom-2 img{
    filter: brightness(1) saturate(0.3);
    opacity: 1;
}
.svg-selected-custom-2 img{
    filter: brightness(1) saturate(18) contrast(0.8);
}

body[data-sidebar="dark"] #sidebar-menu ul li a.active i{
    color: #42affc !important;
}

.svg-selected-custom img{
    filter: brightness(1) saturate(2.5);
    opacity: 1;
}

// SIDEMENU -------------------------------------------------
// COLLAPSED
.vertical-collpsed .vertical-menu{
    position: fixed!important;
    #sidebar-menu > ul > li:hover > a{
        background: #3680ed!important;
        border-radius: 0px 5px 5px 0px;
    }
    #sidebar-menu > ul > li > a span{
        color: white!important;
    }
}

// INBOX TABS -----------------------------------------------
.btn-inbox:hover{ color:#3680ed!important };
.btn-inbox.active{
    background: rgba(47, 128, 237, 0.12);
    color:#3680ed!important;
}

// // inbox assignee icon with user image
// position: absolute;
// width: 22.88px;
// height: 22.88px;
// left: 1276px;
// top: 208.66px;
// background: url(.png);
// background-blend-mode: normal;

// TEAMS TABS -----------------------------------------------
.btn-properties-tab:hover{ color:#3680ed!important }
.btn-properties-tab{
    border-radius: 0px!important;
    padding-bottom: 15px!important;
}

.btn-properties-tab.active{
    color:#3680ed!important;
    border-bottom: 3px solid #3680ed;
    transition: opacity 1s ease-out;
}

//REQUEST FORM TABS ------------------------------------------
.btn-form-tab:hover{ color:#3680ed!important }
.btn-form-tab{
    border-radius: 0px!important;
    padding: 10px!important;
    font-size: 12px!important;
}

.btn-form-tab.active{
    color:#3680ed!important;
    border-bottom: 3px solid #3680ed;
    transition: opacity 1s ease-out;
}

//MEMBER FORM ------------------------------------------------
.member-photo-title{
    font-size: 12px;
    color: #8890AB;
}
.upload-container{
    align-self: center;

    .upload-image-desc{
        color: #8890AB;
        font-size: 12px;
        font-weight: 300;
    }
}

    //User-level cards -------------
    .user-level-card{
        display: flex;
        border: 1px solid #ECECF5;
        border-radius: 4px;
        cursor: pointer;

        .card-title{
            margin: 0!important;
            margin-bottom: 5px!important;
            color: #495057;
            font-weight: 500;
            font-size: 12px;
        }
        .card-description{
            color: #8890AB;
            font-weight: 400;
            font-size: 11px;
        }
    }

    .user-level-card:hover{
        border: 1px solid #2F80ED;
    }

//TEAM MEMBER FORM -------------------------------------------
.team-member-name{
    font-weight: 400;
    font-size: 14px;
    color: #495057;
}

.team-member-email{
    font-weight: 300;
    font-size: 14px;
    color: #495057;
}

.col-12{
    .wrapper input::placeholder{ font-size: 16px };
    .wrapper {
        input{ padding: 0px!important };
        li{ padding: 10px 0 };
        ul{
            border-radius: 5px;
            margin: 0 10px;
            padding: 0;
            box-shadow: rgba(32, 33, 36, 0.28) 0px 0px 6px 0px;
        }
    }

}

// REQUESTS: List
.collapse-container{
    overflow: scroll;
    height: calc(100vh + -230px);
    background: white;
}
.container-body .request-list-header{
    padding: 15px 30px;
    margin-top: -15px;
    background: white;
    border-bottom: 1px solid #ececf5;
}

.collapse-title {
    padding: 12px 15px;
    background: white;
    font-weight: 600;
    font-size: 16px;
    color: #495057;
    border-top: 1px solid #ececf5;
    border-bottom: 1px solid #ececf5;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.collapse-body{
    cursor: pointer;
    padding: 15px 15px;
    border-bottom: 1px solid #EFF2F7;

    .title{
        font-weight: 500;
        font-size: 14px;
        color: #495057;
    }
    .company{
        padding: 0!important;
        font-size: 13px;
        font-weight: 400;
        color: #495057;
    }
    .assignee{
        padding: 0!important;
    }
    .estimated-time{
        padding: 0!important;
        font-size: 13px;
        font-weight: 400;
        color: #495057;
    }
    .due{
        padding: 0!important;
        font-size: 13px;
        font-weight: 500;
        color: #495057;
    }
}

.list-header{
    padding: 0!important;
    font-weight: 500;
}

// Sidebar - Icons
// --- Logs ---
.logs-icon{
    font-size: 15px!important;
    margin: 0 3px;
}

// Datatable - EMAILS ---------------------------------------------
.email-datatable tbody tr { cursor: pointer }
.email-datatable tbody tr:hover{ box-shadow: 0px 1px 10px 0px #cccccc }

// Datatable - LOGS -----------------------------------------------
td > .log-notes{    // -- change parent's style with specific child element
    width: 250px;
}

// Datatable - cursor -----------------------------------------
.properties-datatable tbody tr td { cursor: pointer };
.clickable-datatable tbody tr td { cursor: pointer };
.vertical-collpsed .mm-active .sidebar-submenu{ display:none };

//QUILL -----------------------------------------------------------
.email{
    .ql-container.ql-snow{ border: none!important }
    .ql-editor.ql-blank::before{ left: 0px }
    .ql-editor{
        min-height: 250px;
        padding: 12px 0px;
    }
}

.ql-snow .ql-picker.ql-size{ width: 35px };
.card-bottom-commit {
    .ql-editor{ min-height: unset; padding-right: 95px; }
    .quill{ padding: 10px; padding-bottom: 0; }
    .quill > .ql-container > .ql-editor.ql-blank::before{ 
        padding-right: 95px;
        font-size: 0.8vw;
    }
    .ql-toolbar.ql-snow{
        border:none;
        padding: 0 5px;
        margin-top: 10px;
    }
}
.contact-right-text .ql-editor{ min-height: 200px };
.ql-toolbar.ql-snow .ql-formats{margin-right: 0}
.ql-editor {
    min-height: 150px;
    background: #fff;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    font-family: 'Poppins';
    color: #afb6cb;
    font-style: unset;
}

.react-quill-textarea {
    .ql-editor {
        overflow-y: scroll;
        resize: none;
        // resize: vertical;
    }
}

.note-textarea {
    border: none !important;
}

.note-textarea .ql-container .ql-editor {
    min-height: 200px;
    max-height: 200px;
}

.attachments-image{
    margin: 5px;
    border-radius: 5px;
    background: white;
}

.attachment-container{
    position: relative;
    
    .fa-times-circle{
        // display: none;
        position: absolute;
        top: 12px;
        color: #0707079e;
        cursor: pointer;
        right: 12px;
    }
}

.comments-attachments-container{
    display: flex;
    flex-direction: row;
}

.attachment-item {
    margin-left: 15px;
    width: 91px;
}

.attachment-item:hover {
    filter: brightness(0.5);
}

.attachment-img {
    width: 91px;
    height: 91px;
    cursor: 'pointer';
    object-fit: 'cover';
    border-radius: 4px;
}

.note-attachment-img-container {
    width: 100%;
    height: 60px;
    cursor: 'pointer';
    object-fit: 'cover';
    border-radius: 4px;
}
.note-attachment-img {
    width: 91px;
    height: 91px;
    cursor: 'pointer';
    object-fit: 'cover';
    border-radius: 4px;
}

.attachment-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    overflow-y: auto;
    padding-bottom: 10px;
    margin-right: 12px;
}

.attachment-header {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.add-attachment-button {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #A6B0CF;
}

.add-attachment-card {
    background: #FFFFFF;
    border: 0.5px dashed #A6B0CF;
    box-sizing: border-box;
    border-radius: 5px;
    width: 93px;
    height: 93px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 16px;
    width: 91px !important;
}

.table-attachment {
    margin-left: 11px;
}

.table-attachment td {
    padding: 10px;
}

.attachment-list-row .col-md-6{
    margin-top: 19px;
}

.table-attachment tr, .table-attachment td{
  border: none!important
}

.table-attachment .border-center{
    background: #ECECF5;
    width: 100%;
    min-height: 1px;
}

.attachment-list-row{
    // margin: 0px  70px 0px 0px !important;
    margin-right: 33px !important;
    padding-left: 0px !important;
}

.attachment-list-row small{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #A6B0CF;
}

.attachment-list{
    // padding: 0px 25px;
    line-height: 25px;
    font-size: 12px;
    font-weight: 400;
    color: #575757;
    background: #F8F8FB;
    border: 1px solid #ECECF5;
    box-sizing: border-box;
    border-radius: 4px;
}

.attachment-list th, .attachment-list td{
    border: none !important;
}

// AVATAR ------------------------------------------------------
.avatar-container {
    position: relative;
    text-align: center;
    color: white;

    .avatar-text{
        background: #A6B0CF;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// Request Details HEADER
.request-details-header{
    font-weight: 500;
    padding-right: 0px !important;
    padding-left: 0px !important;
    font-size: 15px;
    color: #495057;
    background: #F8F8FB;
}

.request-title-header{
    background: white;
    padding: 15px 15px;
}

.request-form-description{
    background: white;
    .ql-toolbar.ql-snow{ border: none }
    .ql-container.ql-snow{ border: none!important }
    .ql-editor{padding: 0px 15px;height: calc(100vh - 400px);}
}
.request-form-clarified-description{
    background: white;
    border-left: 1px solid #F8F8FB;
    .ql-toolbar.ql-snow{ border: none }
    .ql-container.ql-snow{ border: none!important }
    .ql-editor{padding: 0px 15px;height: calc(100vh - 400px);}
}

.proposal-description, .email-to-client{
    padding: 12px 25px;
    padding-top: 25px;
    background: #F8F8FB;
    font-weight: 500;
    font-size: 13px;
}

// .attachment-container .attachments-image:hover ~ .fa-times{
//     display: unset;
// }

// .ql-container {
//   border-bottom-left-radius: 0.5em;
//   border-bottom-right-radius: 0.5em;
//   background: #fefcfc;
// }

// /* Snow Theme */
// .ql-snow.ql-toolbar {
//   display: block;
//   background: #eaecec;
//   border-top-left-radius: 0.5em;
//   border-top-right-radius: 0.5em;
// }

// /* Bubble Theme */
// .ql-bubble .ql-editor {
//   border: 1px solid #ccc;
//   border-radius: 0.5em;
// }

// .ql-editor {
//   min-height: 18em;
// }

// .themeSwitcher {
//   margin-top: 0.5em;
//   font-size: small;
// }

.radio-label {
    margin-left: 10px;
}

.radio-label input {
    margin-left: 5px;
}

// timeline toolbar
.zoom-bar {
    background: #ededed;
    height: 40px;
    line-height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;

    .btn-load-more {
        // color: #AFB6CB !important;
        font-weight: 500 !important;
        font-size: 13px;
        line-height: 19px !important;
        color: #2F80ED !important;
        background-color: transparent;
        border-color:#2F80ED !important;
        height: 30px;
        margin: 4px 0 8px 10px;
    }
}
  
.radio-label {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    margin-left: 6px;
    text-align: center;
    width: 70px;
  
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    background: #fff;
    color: rgba(0,0,0,0.7);
}
  
.radio-label > input[type=radio] {
    height: 0;
    margin: 0;
    visibility: hidden;
    width: 0;
}
  
.radio-label-active {
    background: #a0a0a0;
    color: white;
    border-color: #707070;
}

.yn-option:hover {
    cursor: pointer;
}

// PopupForms -----------------------------------------
.popupForm-modal{
    padding: 0 !important;
    width: 50em !important;
}

.popupForm-close{
    top: 0;
    right: 0;
}

// Submit Payment
.popupForm-payment-modal{
    padding: 0 !important;
    max-width: 296px !important;
    height: auto !important;
}
.popupForm-payment-content{
    padding: 0 !important;
    margin: 20px 10px 31px 28px !important;
    #submitPayment{
        margin: 0px !important;
        padding: 0px !important;

        .upload-payment-container{
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
        }

        .contact-group:first-of-type{
            .row-2 input{
                width: 237px !important;
            }
        }

        .contact-group{
            flex-flow:column !important;
            border: none !important;

            .row-2{
                flex-direction: row;
                display: flex;

                input{
                    border: 0 !important;
                    padding: 0 !important;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    width: 251px;
                    color: #495057;
                }

                .form-select{
                    width: 251px !important;

                    div[class$=ValueContainer]{
                        padding: 0 !important;
                    }
                    div[class$=placeholder]{
                        margin: 0 !important;
                    }
                }

                .icon{
                    width: 18px;
                    height: 18px;
                }

                .datepicker{
                    margin-left: 13px;
                    width: 214px;
                }
            }

            .row-2.amount{
                align-items: center;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                span{
                    color: #AFB6CB !important;
                    margin-right: 4px;
                }
            }
        }

        .buttons{
            flex-direction: row !important;

            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;

            .cancel-payment{
                width: 118px;
                height: 35px;
                background: #ECECEC;
                border: 1px solid #ECECEC;
                border-radius: 3px;
                color: #495057 !important;
                margin-right: 15px;
            }

            .add-payment{
                width: 118px;
                height: 35px;
            }
        }

        .label{
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #8890AB;
            padding-right: 20px;
            margin-bottom: 4px;
        }

        .separator{
            width: 251px;
            height: 0px;
            left: 837px;
            top: 413px;
            border: 1px solid #2F80ED;
            margin-bottom: 25px;
            margin-top: -5px;
        }

        .error-fields {
            font-size: 10px;
            color: #dc3545;
            font-weight: 100;
            margin-left: 10px;
        }
    }

    #submitPayment  .row{
        align-items: center;
        margin-bottom: 16px;

        .header{
            color: #495057;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            margin: 0 83px 0 13px;
        }

        .close-manual-payment{
            line-height: 27px;
            padding: 0 !important;
        }

        .close-manual-payment.disabled{
            cursor: default !important;
        }
    }
}

// SubmitQA
.popupForm-QA-content{
    padding: 0 !important;

    #submitQA{      // "Submit Report for QA" container
        padding: 20px;
        padding-left: 30px;

        .header{
            font-size: 18px;
            font-weight: bold;
            text-align: left;
        }

        .subtitle{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
            margin-top: 25px;
        }

        .report-note-container ul{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
        }

        .submit-btn{
            padding: 5px 25px;
            font-size: 13px;
            border: none;
            background: #2F80ED;
            color: white;
            border-radius: 3px;
            float: left;
        }

        .submit-btn:disabled{
            opacity: 0.5;
        }

        #report-note{
            resize: none;
            font-size: 14px;
            width: 100%;
            float: left;
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid rgba(166, 176, 207, 0.5);
            border-radius: 5px;
        }

        .uploads-container{
            .files-uploaded-container{
                max-height: 150px;
                overflow: scroll;
            }
        }
        
    }
    
    #taskDetails{    // "Task Details" container
        background: #eaeaea;
        padding: 20px;
    
        .header{
            font-size: 16px;
            text-align: left;
        }

        p{
            font-size: 14px;
            font-weight: 300;
            color: #343A40;
            text-align: left;
        }

        .subtitle{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
            margin-top: 25px;
        }
       
    }
}

// SendEmail
.popupForm-Email-content{
    padding: 0 !important;

    #sendEmail{
        padding: 20px;
        padding-left: 30px;

        .header{
            font-size: 18px;
            font-weight: bold;
            text-align: left;
        }

        .subtitle{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
            margin-top: 25px;
        }

        .url-for-approval{
            width: 100%;
            padding: 5px;
            border: 1px solid rgba(166, 176, 207, 0.5);
            border-radius: 5px;
        }

        .report-note-container ul{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
        }

        .submit-btn{
            padding: 5px 25px;
            font-size: 13px;
            border: none;
            background: #2F80ED;
            color: white;
            border-radius: 3px;
            float: left;
        }

        .submit-btn:disabled{
            opacity: 0.5;
        }

        #report-note{
            resize: none;
            font-size: 14px;
            width: 100%;
            float: left;
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid rgba(166, 176, 207, 0.5);
            border-radius: 5px;
        }

        .uploads-container{
            .files-uploaded-container{
                max-height: 150px;
                overflow: scroll;
            }
        }
        
    }
    
    #taskDetails{    // "Task Details" container
        background: #eaeaea;
        padding: 20px;
    
        .header{
            font-size: 16px;
            text-align: left;
        }

        p{
            font-size: 14px;
            font-weight: 300;
            color: #343A40;
            text-align: left;
        }

        .subtitle{
            font-size: 12px;
            color: #8890AB;
            text-align: left;
            margin-top: 25px;
        }

        .report-files-container{
            .report-file{
                text-align: left;
                display: flex;
                align-items: center;
                position: relative;
                margin: 10px 0px;

                .file_details{
                    width: 65%;
                    
                    /* File Name */
                    h2{
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        margin: 0;
                        margin-left: 8px;
                        color: #343A40;
                    }

                    /* File Size */
                    h2:last-of-type{
                        font-size: 10px;
                        line-height: 15px;
                        color: #8890AB;
                    }
                }

                i{
                    font-size: 18px;
                    position: absolute;
                    right: 20px;
                }

                i:hover{
                    cursor: pointer;
                    opacity: 0.5;
                }
            }
           
        }
       
    }
}

.request-tab-full-height{
    height: calc(100% + -300px);
}

/** Reports TAB */
.report-created{
    align-items: center;
    position: relative;
    padding: 0px 12px;

    h2{
        margin: 0;
        margin-left: 5px;
        font-size: 0.8vw;
        line-height: 19px;
    }

    .created-by{ 
        font-weight: 500;
        color: #495057 
    };
    .created-at{ 
        font-weight: 300;
        color: #A6B0CF;
        position: absolute;
        right: 12px;
        color: #A6B0CF;
    };
}

.report-note{
    font-size: 0.8vw;
    font-weight: 400;
    color: #495057;
    padding: 8px 12px;
}

.report-attachments{
    margin-top: 12px;
    .report-attachments-title{ 
        padding: 0px 12px;

        h2{
            color: #A6B0CF;
            font-weight: 400;
            margin: 0;
            font-size: 0.8vw;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
                .download-text{
                    margin-left: 2px;
                    color: #2F80ED;
                    cursor: pointer;
                }
            }

            span .download-text:hover{ opacity: 0.5 };
        }
    }

    .report-files {
        border-bottom: 1px solid #efeeee;

        .file-container{ 
            display: flex;
            width: 100%;
            
            .file{
                padding: 8px 0px;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                background: #F8F8FB;
                border: 1px solid #ECECF5;
                box-sizing: border-box;
                border-radius: 4px;
                .file-type{
                    object-fit: cover;
                    height: 35px;
                    margin-right: 5px;
                    border-radius: 4px;
                    width: 35px;
                }
                .file-download{
                    cursor: pointer;
                    color: #2F80ED;
                    font-size: 1.2vw;
                }
                .file-download:hover{
                    opacity: 0.5;
                }
                .file-details{
                    width: calc(100% + -80px);
                    .file-name{
                        max-width: 80%;
                        font-weight: 500;
                        font-size: 0.8vw;
                        line-height: 15px;
                        color: #495057;
                    }
                    .file-size{
                        font-size: 0.8vw;
                        color: #A6B0CF;
                    }
                }
            }
        }
        p{ font-size: 0.8vw }
    }
}

.note-attachments-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.note-attachments {
    padding: 10px 20px 10px 20px;
    height: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    .note-attachments-title { 
        padding: 0px 12px;

        h2 {
            color: #A6B0CF;
            font-weight: 400;
            margin: 0;
            font-size: 0.8vw;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
                .remove-all-text {
                    margin-left: 2px;
                    color: #2F80ED;
                    cursor: pointer;
                }
            }

            span .download-text:hover { opacity: 0.5 };
        }
    }

    .report-files {
        border-bottom: 1px solid #efeeee;

        .file-container { 
            display: flex;
            width: 100%;

            .file {
                padding: 8px 0px;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                background: #F8F8FB;
                border: 1px solid #ECECF5;
                box-sizing: border-box;
                border-radius: 4px;
                .file-type {
                    object-fit: cover;
                    height: 35px;
                    margin-right: 5px;
                    border-radius: 4px;
                    width: 35px;
                }
                .file-download {
                    cursor: pointer;
                    color: #2F80ED;
                    font-size: 1.2vw;
                }
                .file-download:hover {
                    opacity: 0.5;
                }
                .file-details {
                    width: calc(100% + -80px);
                    .file-name {
                        max-width: 80%;
                        font-weight: 500;
                        font-size: 0.8vw;
                        line-height: 15px;
                        color: #495057;
                    }
                    .file-size {
                        font-size: 0.8vw;
                        color: #A6B0CF;
                    }
                }
            }
        }
        p { font-size: 0.8vw }
    }
}

.note-footer-action {
    border-top: none;
    padding: 0 20px 10px 0;
    flex: 1;
}

.notes-form{
    width: 696px !important;
}

.empty-reports{
    opacity: 0.6;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2{
        font-size: 18px;
        text-align: center;
    }
}

/* Attached file reports */
.attached_file_report{
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px 0px;

    .file_details{
        /* File Name */
        h2{
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            margin-left: 8px;
            color: #343A40;
        }

        /* File Size */
        h2:last-of-type{
            font-size: 10px;
            line-height: 15px;
            color: #8890AB;
        }
    }

    i{
        font-size: 18px;
        position: absolute;
        right: 20px;
    }

    i:hover{
        cursor: pointer;
        opacity: 0.5;
    }
}


/** DROP ZONE*/
.dropzone{
    min-height: 150px;
    align-items: center;
    display: flex;
    justify-content: center;

    .needsclick p{
        font-size: 13px;
        font-weight: 300;
        padding: 0px 100px;
        color: #979eb6;

        span{ color: #2f80ed }
    }
}

/* EMAIL THREAD */

//bottom actions
.email-actions-bottom{
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;

    .btn-default{
        margin: 5px;
        max-width: 160px;
        font-size: 0.8vw !important;
    }

    .send-proposal{
        background: #2f80ed !important;
        color: white !important;
    }
}

.tdescription img {
    width: 100% !important;
}

textarea.deployment-notes {
    border: none;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto !important;
    resize: none;
    padding: 2px;
}

textarea.deployment-notes:focus {
    border: none;
    outline: 0;
}

@media (min-width: 1700px){
    .col-6.email-follower-avatar {
        flex: 0 0 50%;
        max-width: 40%;
    }
}

.image-attachment img {
    width: 100px !important;
}

// TASK ACTIVITIES CSS
.activity-list {
    max-height: 700px;
    overflow-y: auto;
}
.activity-desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22.5px;
    // color: #343A40;
}

.activity-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #8890AB;
    margin-top: 4px;
}

.activity-container {
    min-height: 60px;
    max-height: 200px;
    width: 100%;;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    border-bottom: 1px solid #ECECF5;
    overflow: auto;
}

.remove-all-text {
    width: 67px;
    height: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #F46A6A;
    margin: 0 15px;
    cursor: pointer;
}

.note-add-attachment-card {
    width: 21px;
    height: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #A6B0CF;
}

.kanban-org-details {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #74788D;
}

.kanban-org-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #74788D;
}

.kanban-org-details {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #000;
}

.mt-10p {
    margin-top: 10px;
}

.completion-payment {
    font-size: 24px;
    font-family: 'Poppins'!important;
    font-weight: 600;
}